import React from 'react';
import { Typography } from '@material-ui/core';
import PageBase from '../components/PageBase';


export default function HomePage() {
    return (
        <div>
            <PageBase title={'Home Page'} navigation="Tumla / Home">
                <Typography>
            </Typography>
            </PageBase>
        </div>
    );
}
